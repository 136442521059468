import axios from './axiosInstance';
import {User} from "./interfaces/ProjectInterfaces";

export const getUser = async (): Promise<string[]> => {
    const response = await axios.get(`/users`);
    return response.data.data;
};

export const getUserById = async (userId: number): Promise<User> => {
    const response = await axios.get(`/users/${userId}`);
    return response.data;
};