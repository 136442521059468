import React, { useState } from 'react';
import { useQuery } from "react-query";
import { listProjects, removeProject } from "../../../api/project";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import WButton from "../../../components/Wbutton";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {initProjects, setSelectedProject} from "../../../redux/slices/projectSlice";

const getMap = process.env.GET_MAP;

const ProjectsList: React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [coordinatesList, setCoordinatesList] = useState<{ lat: number, lng: number }[]>([]);

    // Fonction pour récupérer les coordonnées géographiques depuis une adresse via OpenStreetMapProvider
    const fetchCoordinatesFromAddress = async (address: string) => {
        const provider = new OpenStreetMapProvider();
        try {
            const results = await provider.search({ query: address });
            if (results && results.length > 0) {
                const { x: lng, y: lat } = results[0]; // Récupération des premières coordonnées disponibles
                return { lat, lng };
            } else {
                console.error("Adresse introuvable:", address);
                return null;
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des coordonnées:", error);
            return null;
        }
    };

    const { data: projects, isLoading } = useQuery('Projects', async () => {
        const projectData = await listProjects();
        // Récupérer les coordonnées pour chaque projet

        dispatch(initProjects(projectData));

        const coordinatesPromises = projectData.map(async (project) => {
            if (project.adresse.latBAN && project.adresse.lngBAN) {
                // Utiliser les coordonnées existantes si elles sont disponibles
                return { lat: project.adresse.latBAN, lng: project.adresse.lngBAN };
            } else {

                if(getMap) {
                    // Si les coordonnées sont nulles, utiliser le géocodage
                    const address = `${project.adresse.adresse}, ${project.adresse.ville}, ${project.adresse.codePostal}`;
                    const coords = await fetchCoordinatesFromAddress(address);

                    return coords || {lat: 0, lng: 0}; // Valeur par défaut si aucune coordonnée n'est trouvée
                } else {
                    return {lat: 0, lng: 0};
                }
            }
        });

        const validCoordinates = await Promise.all(coordinatesPromises);
        const filteredCoordinates = validCoordinates.filter(coord => coord.lat !== 0 && coord.lng !== 0);
        setCoordinatesList(filteredCoordinates); // Mettre à jour l'état avec les coordonnées valides

        return projectData; // Retourne les données de projet
    });

    // Fonction pour supprimer un projet
    const handleDeleteProject = async (projectId: number) => {
        try {
            await removeProject(projectId);
            alert('Projet supprimé avec succès');
        } catch (error) {
            console.error('Erreur lors de la suppression du projet', error);
        }
    };

    const handleNewProject = async () => {
        navigate('/ademe');
    };

    const handleGoProject = async (projectId: number) => {
        dispatch(setSelectedProject(projectId));
        navigate('/single');
    }

    // Création du marqueur personnalisé avec une lettre (ex: "A")
    const customMarkerIcon = L.divIcon({
        html: '<div class="custom-marker"><span class="marker-letter">A</span></div>',
        className: 'custom-div-icon',
        iconSize: [25, 25],
        iconAnchor: [12, 12],
    });

    if (isLoading) {
        return <div>Chargement des données de validation...</div>;
    }

    return (
        <>
            {/* Affichage de la carte avec tous les marqueurs */}
            {coordinatesList.length > 0 && (
                <div className="h-[350px] w-full">
                    <MapContainer
                        center={coordinatesList[0]} // Centrer sur la première coordonnée valide
                        zoom={10}
                        style={{ height: '100%', width: '100%' }}
                        className="w-full p-4 rounded-t-[10px]"
                        bounds={coordinatesList.map(coord => [coord.lat, coord.lng])} // Ajuste automatiquement la carte pour inclure tous les marqueurs
                    >
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                        {/* Affichage de tous les marqueurs */}
                        {coordinatesList.map((coord, index) => (
                            <Marker
                                key={index}
                                position={coord}
                                icon={customMarkerIcon}
                            />
                        ))}
                    </MapContainer>
                </div>
            )}
            <div className="text-left w-full p-14 ">
                <div className="flex flex-col flex-1 bg-white rounded-[10px] shadow-md m-auto w-full">

                    {/* Tableau des projets */}
                    {projects && (
                        <div className="rounded-[10px] ">
                            <div className="flex flex-row justify-between text-center m-6">
                                <div className="font-medium flex justify-center items-center text-2xl">Liste de vos projets</div>
                                <WButton variant="secondary"
                                         onClick={handleNewProject}
                                         className="bottom-4 right-3">Nouveau projet</WButton>
                            </div>
                            <table className="table-auto text-left w-full rounded-[10px]">
                                <thead>
                                <tr className="bg-[#899BB4] text-white h-14">
                                    <th className="p-3 font-bold">Nom du projet</th>
                                    <th className="p-3 font-bold">Adresse</th>
                                    <th className="p-3 font-bold">DPE actuel</th>
                                    <th className="p-3 font-bold">Objectif DPE</th>
                                    <th className="p-3 font-bold">Estimation budget</th>
                                    <th className="p-3 font-bold"></th>
                                    <th className="p-3 font-bold"></th>
                                </tr>
                                </thead>
                                <tbody>
                                {projects.map((project, index) => (
                                    <tr key={project.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                                        <td className="p-3 font-bold">#{project.id} {project.libelle}</td>
                                        <td className="p-3 font-medium text-sm">
                                            {project.adresse.adresse}<br />
                                            {project.adresse.ville} {project.adresse.codePostal}
                                        </td>
                                        <td className="p-3 text-center">{project.classeEnergetiqueActuel}</td>
                                        <td className="p-3 text-center">{project.classeEnergetiqueObjectif}</td>
                                        <td className="p-3">{project.aidesFinancieres}</td>
                                        <td className="p-3">
                                            <button
                                                className="text-blue-500 hover:underline"
                                                onClick={() => handleGoProject(project.id)}
                                            >
                                                👁️
                                            </button>
                                        </td>
                                        <td className="p-3">
                                            <button
                                                className="text-red-500 hover:underline"
                                                onClick={() => handleDeleteProject(project.id)}
                                            >
                                                🗑️
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ProjectsList;
