import React from 'react';
import {useNavigate} from "react-router-dom";
import WButton from "../../../../components/Wbutton";
import {useSelector} from "react-redux";
import {selectCurrentProject} from "../../../../redux/slices/projectSlice";

const Choice: React.FC = () => {
    const navigate = useNavigate();

    const currentProject = useSelector(selectCurrentProject);

    const handlePrevious = () => {
        navigate('/validation')
    };

    const handleOk = () => {
        navigate('/financial')
    };

    const handleLater = () => {
        navigate('/objective')
    };

    // Vérifie si currentProject est nul
    if (!currentProject) {
        return <div>Aucun projet sélectionné.</div>;
    }

    return (
        <>
            <div className="text-left mb-6">

                <div className="flex mt-12 w-[800px]">
                    <div
                        className="text-[19px] leading-7 text-[#5FBB46] border-4 border-[#899BB4] font-bold h-9 w-9 text-center rounded-full">€
                    </div>
                    <div className="w-[calc(100%-2.25rem)]">
                        <div className="font-bold text-lg ml-3 mr-3">Aides financières et subventions</div>
                        <div className="font-normal m-auto ml-3 mr-3">
                            Vous pouvez éventuellement bénéficier d’aides financières pour vous aider dans votre projet
                            de rénovation.
                        </div>
                    </div>
                </div>

                <div className="flex-1 bg-white rounded-[10px] shadow-md w-[800px] m-auto p-14 mt-12 relative">
                    <div className="flex flex-col m-auto justify-center items-center mt-6">
                        <WButton variant="secondary" onClick={handleOk}
                                 className="mb-6 min-w-[500px]">Calculez mon éligibilité aux aides maintenant</WButton>
                        <WButton variant="primary" onClick={handleLater}
                                 className="mb-8 min-w-[500px]">Caculer plus tard</WButton>
                    </div>

                    <p className="mt-12 text-left">De quoi avez-vous besoin pour calculer votre éligibilité aux aides
                        ?</p>
                    <ul className="text-[#7C90AA] text-sm text-left ml-6 mt-6">
                        <li className="relative pl-3 before:content-['•'] before:absolute before:left-0">
                            1 minutes pour remplir le formulaire
                        </li>
                        <li className="relative pl-3 before:content-['•'] before:absolute before:left-0">
                            Code postal du demandeur
                        </li>
                        <li className="relative pl-3 before:content-['•'] before:absolute before:left-0">
                            Revenu fiscal du foyer du demandeur
                        </li>
                        <li className="relative pl-3 before:content-['•'] before:absolute before:left-0">
                            Nombre de personnes dans le ménage du demandeur
                        </li>
                        <li className="relative pl-3 before:content-['•'] before:absolute before:left-0">
                            Est-ce que le demandeur a déjà bénéficier d’un Eco-Prêt à taux zéro pour son logement
                        </li>
                    </ul>

                    <div className="flex justify-between mt-20">
                        <WButton variant="primary" onClick={handlePrevious}>Précédent</WButton>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Choice;