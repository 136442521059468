import React, {useState, FC} from 'react';
import './styles.css';
import {IWInputProps} from "./types";

const WInput: FC<IWInputProps> = ({name, placeholder = "", required = false,
                                      type = "text",
                                      value: propValue = "",
                                      className = "", ...props}) => {
    const [focused, setFocused] = useState(false);

    return (
        <div className={`flex flex-grow floating-label-container ${className} ${(focused || propValue) ? 'focused' : ''}`}>
            <label htmlFor={name} className="floating-label">
                {placeholder} {required && <span className="text-red-500">*</span>}
            </label>
            <input
                type={type}
                id={name}
                name={name}
                className="flex flex-grow floating-input w-full"
                onFocus={() => {
                    setFocused(true)
                }}
                onBlur={() => {
                    setFocused(false)
                }}
                value={propValue}
                required={required}
                {...props}
            />
        </div>
    );
};

export default WInput;
