import axios from './axiosInstance';
import {CreateProjectResponse, Project, Address, PlanActionData} from "./interfaces/ProjectInterfaces";
import {AxiosResponse} from "axios";

export const createProject = async (ademe: string): Promise<Project> => {
    const response: AxiosResponse<CreateProjectResponse> = await axios.post(`/projects/create/residentiel/${ademe}`);
    return response.data.data;
};

export const fetchProjectValidation = async (projectId: number): Promise<Address> => {
    const response = await axios.post(`/projects/${projectId}/validation`);
    return response.data.data;
};

export const updateProject = async (project: Project): Promise<Address> => {
    const response = await axios.put(`/projects`, project);
    return response.data.data;
};

export const listProjects = async (): Promise<[Project]> => {
    const response = await axios.get(`/projects`);
    return response.data.data;
};

export const removeProject = async (projectId: number) => {
    const response = await axios.delete(`/projects/${projectId}`);
    return response.data.data;
};

export const getUsageActif = async (): Promise<string[]> => {
    const response = await axios.get(`/projects/lof/usageactif`);
    return response.data.data;
};

export const getStatusDemandeur = async (): Promise<string[]> => {
    const response = await axios.get(`/projects/lof/statusDemandeur`);
    return response.data.data;
};

export const getPlanAction = async (projectId: number): Promise<PlanActionData[]> => {
    const response = await axios.get(`/projects/${projectId}/planaction`);
    return response.data.data;
};

export interface financialParams {
    statusDemandeur: string,
    usageActif: string,
    dernierRevenuFiscalReference: number,
    nbrPersonnesDeclarees: number,
    dejaBeneficierEcoPret: boolean
}

export const financialAssistance = async (projectId: number, financialParams: financialParams,
) => {
    const response = await axios.post(`/projects/add/financialassistance/${projectId}`, financialParams);
    return response.data.data;
};