import {configureStore} from "@reduxjs/toolkit";
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from "./redux/slices/authSlice";
import userReducer from "./redux/slices/userSlice";
import projectReducer from "./redux/slices/projectSlice";

const persistConfigAuth = {
    key: 'auth',
    storage,
};

const persistConfigUser = {
    key: 'user',
    storage,
};

const persistConfigProject = {
    key: 'project',
    storage,
};

export const store = configureStore({
    reducer: {
        project: persistReducer(persistConfigProject, projectReducer),
        auth: persistReducer(persistConfigAuth, authReducer),
        user: persistReducer(persistConfigUser, userReducer),
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // Désactiver la vérification de sérialisation
        }),
    devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
