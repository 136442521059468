import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectCurrentProject} from "../../../redux/slices/projectSlice";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import L from "leaflet";
import {OpenStreetMapProvider} from "leaflet-geosearch";
import WButton from "../../../components/Wbutton";

const Accordion = () => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const elements = [
        'Mur 1 sud : ID: xdr123658',
        'Mur 2 nord : ID: xdr123658',
        'Mur 3 ouest : ID: xdr123658',
        'Mur 4 est : ID: xdr123658',
        'Mur 5 est : ID: xdr123658'
    ];

    // Fonction pour gérer l'ouverture et la fermeture des éléments
    const handleAccordionClick = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="rounded-lg">
            {elements.map((element, index) => (
                <div key={index} className={`border-b border-gray-300 
                ${index === 0 ? 'rounded-lg':''}  
                ${index === 4 ? 'rounded-b-2xl':''}
                `}>
                    <button
                        className={`w-full text-left p-4 font-medium h-16
                        ${index === 0 ? 'rounded-t-2xl':''}
                        ${index === 4 && activeIndex !== index ? 'rounded-b-2xl':''}
                        ${activeIndex === index ? 'bg-[#5FBB46] text-white' : 'bg-[#E7EEF8] text-black'}`}
                        onClick={() => handleAccordionClick(index)}
                    >
                        {element}
                    </button>
                    {activeIndex === index && (
                        <div className="p-4 bg-white border-t">
                            <p>{element} - Contenu supplémentaire ici...</p>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

const SingleInfo: React.FC = () => {
    const currentProject = useSelector(selectCurrentProject);
    const [coordinates, setCoordinates] = useState<{ lat: number, lng: number } | null>(null);
    const [activeDiv, setActiveDiv] = useState(1);

    useEffect(() => {
        if (currentProject?.adresse) {
            const fullAddress = `${currentProject.adresse.adresse}, ${currentProject.adresse.ville}, ${currentProject.adresse.codePostal}`;
            fetchCoordinatesFromAddress(fullAddress).then();
        }
    }, [currentProject]);

    const handleButtonClick = (divIndex: number) => {
        setActiveDiv(divIndex);
    };

    // Création du marqueur personnalisé avec une lettre (ex: "A")
    const customMarkerIcon = L.divIcon({
        html: '<div class="custom-marker"><span class="marker-letter">A</span></div>',
        className: 'custom-div-icon', // Classe pour personnaliser le style
        iconSize: [25, 25],
        iconAnchor: [12, 12],
    });

    const fetchCoordinatesFromAddress = async (address: string) => {
        const provider = new OpenStreetMapProvider();
        try {
            const results = await provider.search({query: address});
            if (results.length > 0) {
                const {x: lng, y: lat} = results[0];
                setCoordinates({lat, lng});
                console.log({lat, lng});
            } else {
                console.error("Adresse introuvable:", address);
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des coordonnées:", error);
        }
    };

    return <>
        <div className="w-[90%] flex justify-between mt-6">
            <div className="space-x-3">
                <WButton variant="secondary" className=" bottom-4 right-3">Informations</WButton>
                <WButton variant="primary" className=" bottom-4 right-3">Plans d&apos;actions</WButton>
                <WButton variant="primary" className=" bottom-4 right-3">Aides financières</WButton>
                <WButton variant="primary" className=" bottom-4 right-3">Utilisateurs</WButton>
            </div>
            <div /* fin */>
                <WButton variant="primary" className=" bottom-4 right-3">Partager</WButton>
            </div>
        </div>

        <div className="w-[90%] text-left font-bold text-xl mt-12">Informations générales</div>
        {currentProject ? <>

            <div className="flex flex-1 flex-row bg-white rounded-[10px] w-[90%] shadow-md mt-2 relative">
                <div className="w-1/2 p-4">

                    <div className="bg-[#E7EEF8] mb-2 border-[#7C90AA] border h-14 leading-[3.5rem]">Nom du
                        projet: {currentProject.libelle}</div>
                    <div
                        className="bg-[#E7EEF8] mb-2 border-[#7C90AA] border h-14 leading-[3.5rem]">Gestionnaire: {currentProject.userInfo.nom}</div>

                    <table className="table-auto text-left w-full">
                        <tbody>
                        <tr className="bg-gray-100">
                            <td className="p-3 font-bold">Adresse :</td>
                            <td className="p-3">{currentProject?.adresse.adresse}</td>
                        </tr>
                        <tr>
                            <td className="p-3 font-bold">Ville :</td>
                            <td className="p-3">{currentProject?.adresse.ville}</td>
                        </tr>
                        <tr className="bg-gray-100">
                            <td className="p-3 font-bold">Code postal :</td>
                            <td className="p-3">{currentProject?.adresse.codePostal}</td>
                        </tr>
                        <tr>
                            <td className="p-3 font-bold">Typologie :</td>
                            <td className="p-3">{currentProject?.adresse.type}</td>
                        </tr>
                        <tr className="bg-gray-100">
                            <td className="p-3 font-bold">Surface au sol :</td>
                            <td className="p-3">{currentProject?.adresse.surfaceAuSol} m²</td>
                        </tr>
                        <tr>
                            <td className="p-3 font-bold">Surface habitable :</td>
                            <td className="p-3">{currentProject?.adresse.surfaceHabitable} m²</td>
                        </tr>
                        <tr className="bg-gray-100">
                            <td className="p-3 font-bold">Énergies :</td>
                            <td className="p-3">{currentProject?.adresse.energies}</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
                <div className="w-1/2">
                    {coordinates && (
                        <div className="h-full w-full mb-6">
                            <MapContainer
                                center={coordinates}
                                zoom={25}
                                style={{height: '100%', width: '100%'}}
                                className="w-full rounded-br-[10px] rounded-tr-[10px]"
                            >
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                                <Marker position={coordinates} icon={customMarkerIcon}>
                                    <Popup>{currentProject?.adresse.adresse}</Popup>
                                </Marker>
                            </MapContainer>
                        </div>
                    )}
                </div>
            </div>

            <div className="w-[90%] text-left font-bold text-xl mt-12">Informations énergétiques</div>
            <div className="flex flex-row w-[90%]">
                <div className="flex w-1/2 flex-col flex-grow">
                    <div className="flex w-full flex-grow">
                        <div
                            className="shadow-md p-3 flex-col text-white flex bg-[#FF0000] rounded-[10px] w-1/2 m-2 text-center justify-center">
                            Consomation
                            <span className="text-[4rem] leading-normal">G</span>
                            <span className="font-bold">Passoire thermique</span>
                            DPE Actuel
                        </div>

                        <div
                            className="shadow-md p-3 flex-col text-white flex bg-[#9932CD] rounded-[10px] w-1/2 m-2 text-center justify-center">
                            Gaz à Effet de Serre (GES)
                            <span className="text-[4rem] leading-normal">F</span>
                            <span className="font-bold">Passoire thermique</span>
                            DPE Actuel
                        </div>

                    </div>
                    <div className="flex w-full flex-grow">
                        <div
                            className="bg-white flex flex-col flex-grow p-4 rounded-[10px] w-1/2  m-2 text-center justify-center">
                            <div className="text-left font-bold text-xl">Apports gratuits</div>
                            <div className="text-[#7C90AA] font-bold flex-1 flex flex-col justify-center text-left">
                                <div>Apport interne : <span className="text-[#5FBB46]">xx kWh</span></div>
                                <div>Apport solaire : <span className="text-[#5FBB46]">xx kWh</span></div>
                            </div>
                        </div>
                        <div
                            className="shadow-md p-3 flex-col text-white flex bg-[#27C63F] rounded-[10px] w-1/2 m-2 text-center justify-center">
                            Gaz à Effet de Serre (GES)
                            <span className="text-[4rem] leading-normal">F</span>
                            <span className="font-bold">Passoire thermique</span>
                            DPE Actuel
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-1/2 flex-grow">
                    <div
                        className="shadow-md p-5 border-[#FF0000] flex bg-[#FF00001A] border-2 border-dotted items-center rounded-[10px] m-2 text-center justify-center h-1/2">
                        Selon l’article de loi climat & résilience,<br/>
                        vous ne pourrez plus louer votre actif à partir de xxx ni le vendre à partir de xxx
                        <br/><br/>
                        Des actions de performances énergétiques sont à prévoir.
                    </div>
                    <div
                        className="p-4 bg-white flex flex-col rounded-[10px] m-2 text-center justify-center h-1/2">
                        <div className="text-left font-bold text-xl">Répartition par types</div>
                        <div className="flex flex-col flex-1 justify-center">
                            <div className="flex flex-row w-full">
                                <div className="w-[20%] text-right px-4">Electricité</div>
                                <div className="w-[50%] h-5 bg-[#00000010] rounded">
                                    <div className="w-[100%] h-5 bg-[#5FBB46] rounded"></div>
                                </div>
                                <div className="w-[20%] text-left px-4">xx - xx%</div>
                            </div>
                            <div className="flex flex-row w-full">
                                <div className="w-[20%] text-right px-4">Gaz</div>
                                <div className="w-[30%] h-5 bg-[#00000010] rounded">
                                    <div className="w-[100%] h-5 bg-[#00A1FF] rounded"></div>
                                </div>
                                <div className="w-[20%] text-left px-4">xx - xx%</div>
                            </div>

                            <div className="flex flex-row w-full">
                                <div className="w-[20%] text-right px-4">Fioul</div>
                                <div className="w-[10%] h-5 bg-[#00000010] rounded">
                                    <div className="w-[100%] h-5 bg-[#6E6E6E] rounded"></div>
                                </div>
                                <div className="w-[20%] text-left px-4">xx - xx%</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="flex flex-row flex-grow w-[90%] p-2 space-x-4">
                <div className="p-4 bg-white flex flex-col rounded-[10px] w-1/2 text-center justify-center h-auto">
                    <div className="text-left font-bold text-xl">Déperdition énergétique</div>
                    <div className="flex flex-col flex-1 justify-center">
                    </div>
                </div>
                <div className="py-6 px-8 bg-white flex flex-col rounded-[10px] w-1/2 text-center justify-center">
                    <div className="text-left font-bold text-xl">Répartition par usages</div>
                    <div className="flex flex-col flex-1 justify-center  space-y-4">
                        <div>
                            <div className="font-bold text-left">Chauffage : 7077 KWhEF / An</div>
                            <div className="w-full flex">
                                <div className="h-5 w-[5%] bg-[#6E6E6E]"></div>
                                <div className="h-5 w-[20%] bg-[#00A1FF]"></div>
                                <div className="h-5 w-[25%] bg-[#5FBB46]"></div>
                                <div className="text-[#00A1FF] font-bold px-3 h-5">72%</div>
                            </div>
                        </div>

                        <div>
                            <div className="font-bold text-left">Eau chaude : 2 324 KWhEF / An</div>
                            <div className="w-full flex">
                                <div className="h-5 w-[5%] bg-[#6E6E6E]"></div>
                                <div className="h-5 w-[15%] bg-[#00A1FF]"></div>
                                <div className="text-[#00A1FF] font-bold px-3 h-5">24%</div>
                            </div>
                        </div>

                        <div>
                            <div className="font-bold text-left">Eclairage : 191 KWhEF / An</div>
                            <div className="w-full flex">
                                <div className="h-5 w-[2%] bg-[#5FBB46]"></div>
                                <div className="text-[#00A1FF] font-bold px-3 h-5">2%</div>
                            </div>
                        </div>

                        <div>
                            <div className="font-bold text-left">Auxiliaires : 170 KWhEF / An</div>
                            <div className="w-full flex">
                                <div className="h-5 w-[2%] bg-[#5FBB46]"></div>
                                <div className="text-[#00A1FF] font-bold px-3 h-5">2%</div>
                            </div>
                        </div>

                        <div>
                            <div className="font-bold text-left">Auxiliaires : 170 KWhEF / An</div>
                            <div className="w-full flex">
                                <div className="text-[#00A1FF] font-bold px-3 h-5">0%</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="flex flex-row flex-grow w-[90%] p-2">

                <div className="flex flex-row flex-grow w-[90%] bg-white rounded-[10px] shadow-md">
                    <div className="w-1/5 p-5 flex flex-col space-y-4">
                        <WButton onClick={() => handleButtonClick(1)}>Murs</WButton>
                        <WButton onClick={() => handleButtonClick(2)}>Planchers hauts</WButton>
                        <WButton onClick={() => handleButtonClick(3)}>Planchers bas</WButton>
                        <WButton onClick={() => handleButtonClick(4)}>Menuiseries</WButton>
                        <WButton onClick={() => handleButtonClick(5)}>Ponts thermiques</WButton>
                        <WButton onClick={() => handleButtonClick(6)}>Ventilation</WButton>
                        <WButton onClick={() => handleButtonClick(7)}>Climatisation</WButton>
                        <WButton onClick={() => handleButtonClick(8)}>Eau chaude sanitaire</WButton>
                        <WButton onClick={() => handleButtonClick(9)}>Installation chauffage</WButton>
                        <WButton onClick={() => handleButtonClick(9)}>Photovoltaïque</WButton>
                    </div>

                    {/* Div qui change selon le bouton cliqué (80%) */}
                    <div className="w-4/5 p-5">
                        {activeDiv === 1 && <div><Accordion /></div>}
                        {activeDiv === 2 && <div>Contenue 2</div>}
                        {activeDiv === 3 && <div>Contenue 3</div>}
                        {activeDiv === 4 && <div>Contenue 4</div>}
                        {activeDiv === 5 && <div>Contenue 5</div>}
                    </div>
                </div>
            </div>

        </> : (
            <div>Aucun projet sélectionné</div>
        )}
    </>;
};


export default SingleInfo;