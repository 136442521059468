import React from 'react';
import {FiLogOut} from 'react-icons/fi';
import {useDispatch, useSelector} from 'react-redux';
import {removeCurrentProject, selectCurrentProject} from '../redux/slices/projectSlice';
import {useMutation} from 'react-query';
import {removeProject as apiRemoveProject} from '../api/project';
import {useNavigate} from "react-router-dom";

const AbandonProjectButton: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentProject = useSelector(selectCurrentProject);

    const {mutate} = useMutation(
        async ({projectId}: { projectId: number }) => {
             await apiRemoveProject(projectId);

             dispatch(removeCurrentProject());

             return true;

        },
        {
            onSuccess: () => {
                navigate('/projects');
            }
        });

    const handleAbandonner = () => {
        if (currentProject) {
            mutate({projectId: currentProject?.id});
        } else {
            navigate('/projects');
        }
    };

    return (
        <div
            onClick={handleAbandonner}
            className="flex items-center space-x-2 cursor-pointer text-red-500"
        >
            <FiLogOut className="h-6 w-6"/>
            <span className="font-normal text-gray-500">Abandonner</span>
        </div>
    );
};

export default AbandonProjectButton;
